import React from "react"
import Markdoc from "@markdoc/markdoc"
import { config } from "../markdoc/utils"
import { Section } from "../markdoc/components/Section"
import { Fence } from "../markdoc/components/Fence"
import { Table } from "../markdoc/components/Table"

const IkApiOverview = () => {
	const fileName = "ikApi.md"
	const [shopstarContent, setShopstarContent] = React.useState<any>(null)

	React.useEffect(() => {
		import(`../markdoc/content/${fileName}`)
			.then((res) => {
				fetch(res.default)
					.then((res) => res.text())
					.then((rawText) => {
						const ast = Markdoc.parse(rawText)
						console.log("ast: ", ast)
						const content = Markdoc.transform(ast, config)
						console.log("shopstar content: ", content)
						setShopstarContent(content)
					})
					.catch((err) => console.log("err: ", err))
			})
			.catch((err) => console.log(err))
	}, [])

	return (
		<>
			{Markdoc.renderers.react(shopstarContent, React, {
				components: {
					Section: Section,
					Fence: Fence,
					Table: Table,
				},
			})}
		</>
	)
}
export default IkApiOverview
