import { uppercase } from "./functions";

export const config = {
	variables: {
		name: "Ecomm",
	},
	functions: {
		uppercase,
	},
	tags: {
		section: {
			render: "Section",
			attributes: {
				type: {
					type: String,
					required: true,
					matches: ["warning", "note", "caution", "check"],
				},
			},
		},
		table: {
			render: "Table",
			attributes: {
				title: {
					type: String,
					required: true,
				},
				width: {
					type: String,
					required: true,
				},
				padding: {
					type: String,
					required: true,
				},
				maxHeight: {
					type: String,
					required: true,
				},
				minHeight: {
					type: String,
					required: true,
				},
				overflowY: {
					type: String,
					required: true,
				},
			},
		},
	},
	nodes: {
		fence: {
			render: "Fence",
			attributes: {
				title: {
					type: String,
					required: true,
				},
				description: {
					type: String,
					required: true,
				},
				width: {
					type: String,
					required: true,
				},
				content: { type: String, render: false, required: true },
				language: { type: String, render: "javascript" },
				process: { type: Boolean, render: false, default: true },
			},
		},
	},
}