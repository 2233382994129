import React from "react"
import Markdoc from "@markdoc/markdoc"
import { config } from "../markdoc/utils"
import { Section } from "../markdoc/components/Section"
import { Fence } from "../markdoc/components/Fence"
import { Table } from "../markdoc/components/Table"
import iKNavToApi from "../theme/assets/iKNavToApi.png"
import iKGenNewKey from "../theme/assets/iKGenNewKey.png"

const LandingPage = () => {
    const fileName = "landingPage.md"
    const [content, setContent] = React.useState<any>(null)

    React.useEffect(() => {
        import(`../markdoc/content/${fileName}`)
            .then((res) => {
                fetch(res.default)
                    .then((res) => res.text())
                    .then((rawText) => {
                        rawText = rawText.replace("navToApi", iKNavToApi).replace("genNewKey", iKGenNewKey)
                        const ast = Markdoc.parse(rawText)
                        // console.log("ast: ", ast)
                        const contentTemp = Markdoc.transform(ast, config)
                        // console.log("shopstar content: ", contentTemp)
                        setContent(contentTemp)
                    })
                    .catch((err) => console.log("err: ", err))
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <>
            {Markdoc.renderers.react(content, React, {
                components: {
                    Section: Section,
                    Fence: Fence,
                    Table: Table,

                },
            })}
        </>
    )
}
export default LandingPage
