import React from "react"
import { Box, Card } from "@mui/material"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import CopyToClipboardButton from "../../components/CopyToClipboardButton"

const style = {
	maxHeight: "350px",
	minHeight: "auto",
	margin: "0",
	backgroundColor: "#FAFAFA",
}

export function Fence({ children, language, title, description, width }) {
	return (
		<Card
			elevation={4}
			style={{
				borderRadius: "16px",
				width: width,
				
			}}
		>
			<Box
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "20px",
					background: '#EEE',
                    color: '#1D1D1B',
					
				}}
			>
				<Box>{title}</Box>
				<CopyToClipboardButton contentToCopy={children} />
			</Box>
			<SyntaxHighlighter language="javascript" customStyle={style}>
				{children}
			</SyntaxHighlighter>
		</Card>
	)
}
