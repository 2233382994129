import { Box, Card } from '@mui/material';
import React from 'react';
//import { theme } from '@ikhokha/commons-ui/build/dist/cjs/theme';

export function Table({ children, title, width, padding, maxHeight, minHeight, overflowY }) {
     
    return (
        <Card elevation={4}
            style={{
                borderRadius: '16px',
                width: width
            }}
        >
            <Box
                style={{
                    padding: '20px',
                    background: '#EEE',
                    color: '#1D1D1B',
                    width: '100%',
                }}>
                {title}
            </Box>
            <Box sx={{
                background: '#FAFAFA',
                width: '100%',
                padding: padding,
                maxHeight: maxHeight+10,
                minHeight: minHeight,
                overflowY: overflowY
            }} >
                {children}
            </Box>
        </Card>
    );
}