import React from 'react';


export function Section({ children, type }) {
  return (
    <div
      className="section"
      style={{
       // backgroundColor: TYPE_MAP[type].color
        backgroundColor: '#FAFAFA',
      }}
    >
      {children}
    </div>
  );
}