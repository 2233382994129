import "./index.css"
import { Sidenav } from "./components/Sidenav"

export default function App() {
	return (
		<>
			<Sidenav></Sidenav>
		</>
	)
}
