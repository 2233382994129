import { Box, Snackbar, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

interface CopyToClipboardProps {
	contentToCopy: string
}

const delay = 3000

const CopyToClipboardButton: React.FC<CopyToClipboardProps> = ({ contentToCopy }) => {
	const [isCopied, setIsCopied] = React.useState(false)

	const handleCopy = () => {
		setIsCopied(true)
	}

	const handleCloseSnackbar = () => {
		setIsCopied(false)
	}

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsCopied(false)
		}, delay)

		return () => clearTimeout(timeoutId)
	}, [isCopied])

	return (
		<Box>
			<CopyToClipboard text={contentToCopy} onCopy={handleCopy}>
				{!isCopied ? <ContentCopyIcon /> : <></>}
			</CopyToClipboard>
			{isCopied && <Typography>Copied!</Typography>}
			<Snackbar open={isCopied} autoHideDuration={2000} onClose={handleCloseSnackbar} message="Copied to clipboard!" />
		</Box>
	)
}

export default CopyToClipboardButton
