import React from "react"
import {
	List,
	ListItem,
	ListItemText,
	IconButton,
	Divider,
	ListItemButton,
	ListItemIcon,
	Toolbar,
	Typography,
	Box,
	CssBaseline,
	Collapse,
} from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import MenuIcon from "@mui/icons-material/Menu"
import { styled, Theme, CSSObject, useTheme } from "@mui/material/styles"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ShopstarV2 } from "../pages/ShopstarV2"
import NavItem from "./NavItem"
import ShopstarImg from "../theme/assets/ikIconLogo.svg"
import IkApiOverview from "../pages/IKApi"
import LandingPage from "../pages/landingPage"

const drawerWidth = 290
const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}))

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

export const Sidenav = () => {
	const [open, setOpen] = React.useState(true)
	const [openNestedShopstarList, setOpenNestedShopstarList] = React.useState(true)
	const theme = useTheme();
 
	const handleMenu = () => {
		setOpen(!open)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const handleNestedShopstarListToggle = () => {
		setOpenNestedShopstarList(!openNestedShopstarList)
	}

	return (
		<Router>
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBar position="fixed" elevation={4} sx={{ backgroundColor: "#FFFFFF" }}>
					<Toolbar
						sx={{
							background: theme.palette.common.black,
						}}
					>
						<IconButton color="inherit" aria-label="open drawer" onClick={handleMenu} edge="start"
							sx={{

								color: theme.palette.common.white,
							}}>
							<MenuIcon />
						</IconButton>
						<Typography
							sx={{
								marginLeft: "2rem",
								color: theme.palette.common.white,
							}}
							variant="h6"
							noWrap
							component="div"
						>
							iK Pay API
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer variant="permanent" open={open}>
					<DrawerHeader>
						<IconButton onClick={handleDrawerClose}>
							<ChevronRightIcon />
						</IconButton>
					</DrawerHeader>
					<Divider />
					<List>
						{/* Main Shopstar List Item */}
						<ListItem onClick={handleNestedShopstarListToggle} disablePadding sx={{ display: "block" }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? "initial" : "center",
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 1 : "auto",
										justifyContent: "center",
									}}
								>
									<img
										style={{
											height: "1.8rem",
											width: "2.015rem",
										}}
										src={ShopstarImg}
										alt="Shopstar icon"
									/>
								</ListItemIcon>
								<ListItemText primary="iK Pay API" sx={{ opacity: open ? 1 : 0 }} />
								{open &&
									(openNestedShopstarList ? (
										<ListItemIcon
											sx={{
												justifyContent: "end",
											}}
										>
											<ExpandLess />
										</ListItemIcon>
									) : (
										<ListItemIcon
											sx={{
												justifyContent: "end",
											}}
										>
											<ExpandMore />
										</ListItemIcon>
									))}
							</ListItemButton>
						</ListItem>
						{/* Nested Shopstar List Items */}
						{open && (
							<Collapse in={openNestedShopstarList} timeout="auto" unmountOnExit>
								<List component="div" disablePadding sx={{
									marginLeft: "2rem",
									backgroundColorcolor: theme.palette.common.white,
								}}>
									<NavItem to="/" text="Overview" open={open}></NavItem>
									<NavItem to="/overview" text="API Overview" open={open}></NavItem>
									{/*<NavItem to="/shopstar-api-spec-v2" text="Api Spec V2" open={open}></NavItem> *}*/}
								</List>
							</Collapse>
						)}
						{/* Main Wix List Item */}
					 
						{/* Nested Wix List Items */}
						 
					</List>
				</Drawer>
				{/* Main Content */}
				<Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
					<Routes>
						<Route path="/" element={<LandingPage />} />
						<Route path="/overview" element={<IkApiOverview />} />

						<Route path="/shopstar-api-spec-v2" element={<ShopstarV2 />} />
						 
					</Routes>
				</Box>
			</Box>
		</Router>
	)
}
