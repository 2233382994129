import { useTheme } from "@mui/material/styles"
import { ListItemButton, ListItemText } from "@mui/material"
import { NavLink, useLocation } from "react-router-dom"

interface NavItemProps {
	to: string
	text: string
	open: boolean
}

const NavItem: React.FC<NavItemProps> = ({ to, text, open }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const theme = useTheme()

	const location = useLocation()
	return (
		<ListItemButton component={NavLink} to={to} //className={location.pathname === to ? "active-navlink" : "inactive-navlink"}


			sx={{
				bgcolor: location.pathname === to ? '#FFEDB0' : 'inherit', // Active color
			}}>
			<ListItemText
				sx={{
					paddingLeft: "1.5rem",
				}}
				primary={text}
			/>
		</ListItemButton>
	)
}

export default NavItem
